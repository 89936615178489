import { Routes, Route } from "react-router-dom";
import Button from "./Button";
import Input from "./Input";
import Screen from "./Screen";
import "./styles.css";

/* Change debug to `true` to see the invisible Buttons */
export const debug = false;

/* Step 1
<---- UPLOAD YOU OWN IMAGE FILES INTO /public
You can use .jpg, .png, .svg
Try to make all your images roughly the same width, 
i.e. 360 pixels wide for a mobile screen
or 1200 pixels wide for a desktop screen
*/

export default function App() {
  /* Step 2
  For each Screen in your click dummy, create a new
  - Route
    - Screen
  If you want to make the screens interactive, place a Button inside the screen
  – Route
    – Screen
      – Button
      – Button
  The Button "to" parameter links to the Route "path" parameter.
  The Screen "image" parameter should be the file name in the / folder (root)
  */

  const fetchData = () => {
    fetch("http://localhost:8080/endpoint")
      .then((res) => res.text()) // text as we are returning a string not json
      .then(
        (result) => {
          alert(result);
        },
        // Note: it's important to handle errors
        (error) => {
          alert(error);
        }
      );
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Screen image="/Exp.png">
              <Button to="/Story1" x="430" y="635" w="330" h="80" />
            </Screen>
          }
        />

        <Route
          path="/Story1"
          element={
            <Screen image="/Story 1.png">
              <Button to="/Story2" x="70" y="240" w="200" h="109" />
              <Button to="/Story2" x="340" y="130" w="193" h="59" />
              <Button to="/Story2" x="720" y="500" w="400" h="120" />
            </Screen>
          }
        />

        <Route
          path="/Story2"
          element={
            <Screen image="/Story 2.png">
              <Button to="/Story3" x="330" y="625" w="60" h="44" />
              <Button to="/Story3" x="810" y="625" w="60" h="44" />
              <Button to="/Story1" x="1055" y="70" w="30" h="30" />
            </Screen>
          }
        />

        <Route
          path="/Story3"
          element={
            <Screen image="/Story 3.png">
              <Input type="checkbox" x="499" y="678" w="22" h="22" />
              <Button to="/Story4" x="530" y="630" w="140" h="40" />
            </Screen>
          }
        />

        <Route
          path="/Story4"
          element={
            <Screen image="/Story 4.png">
              <Button to="/Story5" x="70" y="370" w="200" h="109" />
              <Button to="/Story5" x="720" y="630" w="400" h="120" />
            </Screen>
          }
        />

        <Route
          path="/Story5"
          element={
            <Screen image="/Story 5.png">
              <Button to="/Story6" x="330" y="625" w="60" h="44" />
              <Button to="/Story4" x="1055" y="70" w="30" h="30" />
            </Screen>
          }
        />

        <Route
          path="/Story6"
          element={
            <Screen image="/Story 6.png">
              <Input type="checkbox" x="506" y="669" w="22" h="22" />
              <Button to="/Story7" x="530" y="600" w="140" h="40" />
            </Screen>
          }
        />

        <Route
          path="/Story7"
          element={
            <Screen image="/Story 7.png">
              <Button to="/Story8" x="750" y="610" w="400" h="140" />
            </Screen>
          }
        />

        <Route
          path="/Story8"
          element={
            <Screen image="/Story 8.png">
              <Button to="/Story7" x="1130" y="30" w="30" h="30" />
              <Button to="/Story9" x="520" y="1030" w="161" h="38" />
            </Screen>
          }
        />

        <Route
          path="/Story9"
          element={
            <Screen image="/SaveAs.png">
              <Button to="/Story7" x="720" y="546" w="83" h="20" />
              <Button to="/Story7" x="820" y="546" w="83" h="20" />
            </Screen>
          }
        />

        <Route
          path="/connect-backend"
          element={
            <button onClick={() => fetchData()}>
              Click here to connect to Backend !
            </button>
          }
        />
      </Routes>
    </div>
  );
}
